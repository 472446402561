import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { FormDataAssignCarrierAndDriver } from "@wearewarp/types/rest-api/admin/form-data/dispatch";
import { environment } from "@env/environment";
import { DialogService } from "@dialogs/dialog.service";
import { AddNewDriver } from "./add-new-driver";
import { Log } from "@services/log";
import { ChangeDriverV2 } from "./change-driver";
import { BizUtil } from "@services/biz";
import {DateUtil} from "@services/date-utils";
import { VehicleType } from "@wearewarp/types/data-model";
import { ChangeSecondaryDriver } from "./change-secondary-driver";
import { ConfirmUnassignCarrier } from "@app/components/confirm-unassign-carrier";

@Component({
  selector: '[dispatch-assign-carrier-driver]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class DispatchAssignCarrierAndDriver extends BaseFormDialog1<FormDataAssignCarrierAndDriver> {

  @Input() jobId;
  @Input() jobCode;
  @Input() set action(value: 'assign-carrier' | 'assign-driver' | 'assign-secondary-driver' | 'assign-carrier-external-route') {
    if (value == 'assign-carrier') {
      this.tabIndex = 0;
    } else if (value == 'assign-driver') {
      this.tabIndex = 1;
    } else if (value == 'assign-secondary-driver') {
      this.tabIndex = 2;
    } else if (value == 'assign-carrier-external-route') {
      this.isAssignCarrierExternalRoute = true;
    }
  }
  isAssignCarrierExternalRoute = false;
  isShowSecondaryDriverTab = false;
  @Input() set vehicle(value: VehicleType) {
    if ((value?.options ?? []).includes('TEAM')) {
      this.isShowSecondaryDriverTab = true;
    }
  };
  public carrierId;
  public driverId;
  public isLoading = true;
  public carrierWebUrl = '';
  public tabIndex = 0; // 0 : carrier, 1 : driver, 2: Secondary Driver
  public equipmentInfo;
  public isJobStarted;
  public driverToken;
  public driverObj;
  public shouldDisableDriverTab = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    assignedCarrier: { label: 'Carrier', type: "formGroup", childItem: {
      carrierId: { label: "Carrier", required: true },
    }},
    assignedDriver: { label: 'Driver', type: "formGroup", notAcceptEmpty: true, childItem: {
      driverId: { label: "Driver", required: false, notAcceptEmpty: true },
    }},
    assignedSecondaryDrivers: { label: 'Secondary Driver', type: 'formArray', initialValue: [{}], childItem: {
      driverId: { label: "Secondary Driver", required: false},
    }},
    trailerNumber: { label: "Trailer Number", type: 'string', notAcceptEmpty: true },
  };

  get isCreateNew(): boolean {
    return !this.model;
  }

  constructor() {
    super();
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  get headerText() {
    return this.model?.assignedCarrier?.carrierId ? 'Edit Carrier & Driver' : 'Assign Carrier & Driver';
  }

  get isAssignedCarrier() {
    return this.model?.assignedCarrier?.carrierId;
  }

  get shouldDisableFormCarrier() {
    if (this.carrierId) return true;
    if (this.isAssignCarrierExternalRoute) return false;
    return true;
  }

  get btnSubmitLabel() {
    if (this.tabIndex == 0) {
      return this.model?.assignedCarrier?.carrierId ? 'Update' : 'Assign';
    } else if (this.tabIndex == 1) {
      return this.model?.assignedDriver?.driverId ? 'Update' : 'Assign';
    } else if (this.tabIndex == 2){
      return this.model?.assignedSecondaryDrivers?.[0]?.driverId ? 'Update' : 'Assign';
    }
    return 'Assign';
  }

  onTabChange(index){
    this.tabIndex = index;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.carrierWebUrl = `${environment.carrierWebUrl}/dashboard/my-loads/${this.jobId}`;
  }

  private async getData() {
    let url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}`;
    this.api.GET(url).subscribe(
      async (resp) => {
        let modelData: FormDataAssignCarrierAndDriver = {};
        if (resp.data?.assignedCarrier?.carrierId) {
          modelData.assignedCarrier = resp.data.assignedCarrier;
          this.carrierId = resp.data.assignedCarrier.carrierId;
        }
        if (resp.data?.assignedDriver?.driverId) {
          modelData.assignedDriver = resp.data.assignedDriver;
          this.driverId = resp.data.assignedDriver.driverId;
          this.driverToken = resp.data.assignedDriver.token;
          await this.fetchDriverDetail(resp.data.assignedDriver.driverId);
        }
        if (resp.data?.assignedSecondaryDrivers?.[0]?.driverId) {
          modelData.assignedSecondaryDrivers = resp.data.assignedSecondaryDrivers ?? [{ driverId: null }];
        } else {
          modelData.assignedSecondaryDrivers = [{ driverId: null }];
        }
        if (resp.data?.trailerNumber) {
          modelData.trailerNumber = resp.data.trailerNumber;
        }
        this.model = modelData;
        this.equipmentInfo = this.getEquipmentByJob(resp.data);
        this.isJobStarted = this.getIsJobStarted(resp.data)
        if (!this.carrierId) this.shouldDisableDriverTab = true;
        this.getDataDone();
      },
      (err) => {
        this.getDataDone(err);
      }
    );
  }

  async fetchDriverDetail(id: string) {
    let url = `${Const.APIURI_DRIVERS}/${id}`;
    const resp = await this.api.GET(url).toPromise();
    this.driverObj = resp.data;
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
    } else {
      if (this.isJobStarted) {
        this.formGroupDeclaration.trailerNumber.readOnly = true;
        this.formGroupDeclaration.trailerNumber.submitReadOnly = true;
      }
      this.createFormInput(this.model);
      this.setEnableFormGroup(true); // make sure all readOnly fields will be disabled after created.
    }

    this.isLoading = false;
    if (this.isAdminReadOnlyRole) {
      setTimeout(() => this.setEnableFormGroup(false), 1);
    }
  }

  public get isDisableFormDriver() {
    return this.isJobStarted;
  }

  onBtnCreateNewDriver() {
    if (!this.carrierId) return;
    DialogService.openFormDialog1(AddNewDriver, {
      nzComponentParams: {
        carrierId: this.carrierId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          let driver = resp.data;
          if (driver.id) {
            this.setItemValue('assignedDriver.driverId',driver.id);
          }
        }
      },
      nzClassName: 'modal',
    });
  }

  onCarrierChange(value) {
    if (this.carrierId && this.carrierId != value) {
      this.shouldDisableDriverTab = true;
    }
  }

  onBtnAssignCarrierDriver() {
    if (this.tabIndex == 0) {
      this.onBtnAssignCarrier();
    } else if (this.tabIndex == 1) {
      this.onBtnAssignDriver();
    } else if (this.tabIndex == 2) {
      this.onBtnAssignSecondaryDriver();
    }
  }

  onBtnAssignCarrier() {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    let data: FormDataAssignCarrierAndDriver = this.getFormData_JSON(true);
    const params = data.assignedCarrier;
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/assign_carrier`, params).subscribe(
      (resp) => {
        Log.d("assignCarrier done ", resp);
        let msg = 'Carrier has been assigned successfully.<br>Load tender is being generated, please wait a few seconds then refresh the page to see/download it.';
        this.showDialog(msg, () => {});
        this.onUpdateSuccess(resp);
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  // private async addTrailerNumber() {
  //   const trailerNumber = this.formInput?.get('trailerNumber')?.value;
  //   let resp = await this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/trailer_number`, {trailerNumber}).toPromise()
  //   Log.d('add trailer number in assign driver modal done ', resp);
  //   return resp?.data?.trailerNumber
  // }

  private doAssignDriver(params) {
    this.setEnableFormGroup(false);
      this.startProgress();
      // const trailerNumber = await this.addTrailerNumber();
      this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/assign_driver`, params).subscribe(
        (resp) => {
          Log.d("assignDriver done ", resp);
          let msg = 'Driver has been assigned successfully.';
          this.showDialog(msg, () => {});
          this.onUpdateSuccess(resp);
          this.stopProgress();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.setEnableFormGroup(true);
        }
      );
  }

  private async onBtnAssignDriver() {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    let data: FormDataAssignCarrierAndDriver = this.getFormData_JSON(true);
    const params = data.assignedDriver;
    if (params.driverId == this.model.assignedSecondaryDrivers?.[0]?.driverId) {
      this.confirmYesNo('This driver is currently a secondary driver, do you want to change to primary driver?', () => {
        this.doAssignDriver(params);
      });
    } else {
      this.doAssignDriver(params);
    }
  }

  private async onBtnAssignSecondaryDriver() {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    let data: FormDataAssignCarrierAndDriver = this.getFormData_JSON(true);
    const driverIds = (data.assignedSecondaryDrivers ?? []).map(it => it.driverId);
    if (!driverIds?.length) {
      return;
    }
  
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/assign_secondary_driver`, { driverIds }).subscribe(
      (resp) => {
        Log.d("assignDriver done ", resp);
        let msg = 'Secondary driver has been assigned successfully.';
        this.showDialog(msg, () => {});
        this.onUpdateSuccess(resp);
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  onBtnUnassignCarrier() {
    DialogService.openFormDialog1(ConfirmUnassignCarrier, {
      nzComponentParams: {
        jobId: this.jobId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          console.log("resp", resp)
          this.setEnableFormGroup(false);
        }
      },
      nzClassName: 'modal-no-padding confirm-unassign-carrier-form',
    });
  }

  onBtnUnassignDriver() {
    this.confirmYesNo('Do you really want to unassign driver?', () => {
      this.unassignDriver();
    });
  }

  onBtnUnassignSecondaryDriver() {
    this.confirmYesNo('Do you really want to unassign secondary driver?', () => {
      this.unassignSecondaryDriver();
    });
  }

  private unassignDriver() {
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/unassign_driver`, null).subscribe(
      resp => {
        Log.d('unassignDriver done ', resp);
        let msg = 'Unassign Driver done.';
        this.showDialog(msg, () => {});
        this.onUpdateSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  private unassignSecondaryDriver() {
    if (!this.jobId) {
      return Log.e('jobId is required');
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    this.api.PUT(`${Const.APIV2(Const.APIURI_JOBS)}/${this.jobId}/unassign_secondary_driver`, null).subscribe(
      resp => {
        Log.d('unassign Secondary Driver done ', resp);
        let msg = 'Unassign Secondary Driver done.';
        this.showDialog(msg, () => {});
        this.onUpdateSuccess(resp);
        this.stopProgress();
      }, err => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  onBtnChangeDriverWhileJobStarted() {
    if (!this.isAdmin || this.isAdminReadOnlyRole) return;
    this.closeDialog();
    DialogService.openFormDialog1(ChangeDriverV2, {
      nzComponentParams: {
        jobId: this.jobId,
        carrierId: this.carrierId,
        driverId: this.driverId,
        secondaryDriverId: this.model.assignedSecondaryDrivers?.[0]?.driverId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.showDialog('Change Driver successfully.')
          this.onUpdateSuccess(resp);
        }
      },
      nzClassName: 'modal',
    });
  }

  onBtnChangeSecondaryDriverWhileJobStarted() {
    if (!this.isAdmin || this.isAdminReadOnlyRole) return;
    this.closeDialog();
    DialogService.openFormDialog1(ChangeSecondaryDriver, {
      nzComponentParams: {
        jobId: this.jobId,
        carrierId: this.carrierId,
        driverId: this.model.assignedSecondaryDrivers?.[0]?.driverId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          this.showDialog('Change Secondary Driver Successfully.')
          this.onUpdateSuccess(resp);
        }
      },
      nzClassName: 'modal',
    });
    
  }

  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  copyCarrierUrl() {
    Utils.copyTextToClipboard(this.carrierWebUrl, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('URL has already been copied to the clipboard');
      }
    })
  }

  private getEquipmentByJob(job) {
    let arr = [];
    const vehicle = job?.requiredVehicle;
    if (job?.requiredVehicle) {
      return BizUtil.getVehicleName(vehicle)
    }
    if (Utils.isArrayNotEmpty(job?.shipments)) {
      for (let shipment of job.shipments) {
        if (shipment.shipmentModeId) {
          let shipmentMode = MasterData.getShipmentModeNameById(shipment.shipmentModeId) ?? '';
          if (shipment.equipmentId) {
            let equipment = MasterData.getEquipmenNameById(shipment.equipmentId);
            if (equipment) {
              if (shipmentMode) {
                shipmentMode += '/';
              }
              shipmentMode += equipment;
            }
          }
          if (!arr.includes(shipmentMode)) {
            arr.push(shipmentMode);
          }
        } else if (shipment.equipmentId) {
          let equipment = MasterData.getEquipmenNameById(shipment.equipmentId);
          if (!arr.includes(equipment)) {
            arr.push(equipment);
          }
        }
      }
    }
    return arr.join(', ');
  }

  private getIsJobStarted(job): boolean {
    const tasks = job.tasks || [];
    const startedTasks = tasks.filter(task => task.status && task.status != Const.TaskStatus.created);
    //nếu task chưa start thì coi như chưa bắt đầu. fix WPD-1308
    if (!startedTasks.length) {
      return false;
    }
    switch (job.status) {
      case Const.JobStatus.inProgress:
      case Const.JobStatus.completed:
        return true;
      default:
        return false;
    }
  }

}
